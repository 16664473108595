import { useNavigate } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';
import { runInAction } from 'mobx';

import Table from '../../../Components/Table';
import TablePageHeading from '../../../Components/TablePageHeading';
import LinkAddButton from '../../../Components/LinkAddButton';
import useAPI from '../../../hooks/useAPI';
import { MenuConfig } from '../../../Components/ActionMenu';
import StoresContext from '../../../Stores';
import modals from '../../../utils/modals';
import DateTime from '../../../Components/DateTime';

const status2mex = {
  done: 'timbrado',
  created: 'creado',
  creating: 'creando',
  running: 'timbrando',
  failed: 'fallado',
  'creation-failed': 'creación fallada',
  're-creating': 'recreando'
};

const columns = [
  {
    key: 'createdAt',
    label: 'Fecha creación',
    sortable: true,
    template: (cell) => <DateTime>{cell}</DateTime>
  },
  {
    key: 'transmitterCompanyName',
    label: 'Nombre Emisor',
    sortable: true,
    template: (cell, row) => row.transmitterCompany?.name
  },
  {
    key: 'transmitterCompanyRFC',
    label: 'RFC Emisor',
    sortable: true,
    template: (cell, row) => row.transmitterCompany?.rfc
  },
  {
    key: 'recipientName',
    label: 'Nombre Receptor',
    sortable: true,
    template: (cell, row) => row.batchItems[0]?.newRecipientName
  },
  {
    key: 'recipientRFC',
    label: 'RFC Receptor',
    sortable: true,
    template: (cell, row) => row.batchItems[0]?.newRecipientRFC
  },
  {
    key: 'status',
    label: 'Estatus',
    template: (cell) => status2mex[cell]
  }
];

const deleteAction = async ({ row, reInvoice, mutate, setCurrentPage }) => {
  const confirmed = await modals.confirm(
    'Confirmar eliminar registro tipo Batch',
    'Estas seguro que deseas borrar este Batch?'
  );
  if (confirmed) {
    await reInvoice.delete(row);

    // Check if we need to adjust the current page
    if (reInvoice.items.length === 1 && reInvoice.pagination.page > 1) {
      setCurrentPage(reInvoice.pagination.page);
    }

    mutate();
  }
};

const rowMenuConfig = new MenuConfig(
  [
    {
      key: 'open',
      label: 'Abrir',
      onClick: ({ row, navigate }) => navigate(`/reinvoice/view/${row.id}`)
    },
    {
      key: 'stamp',
      label: 'Timbrar',
      validator: ({ row }) => row.status === 'created',
      onClick: ({ row, reInvoice }) => {
        reInvoice.stamp(row);
      }
    },
    {
      key: 'recalculate',
      label: 'Recalcular fallados',
      validator: ({ row }) => ['created', 'failed'].includes(row.status),
      onClick: ({ row, reInvoice }) => {
        reInvoice.recalculate(row);
      }
    },
    {
      key: 'delete',
      label: 'Borrar',
      onClick: deleteAction,
      validator: ({ row }) => ['created', 'done'].includes(row.status)
    }
  ],
  {
    validator: (validator, row) => validator({ row })
  }
);

function CreateBatchButton() {
  return <LinkAddButton to="/reinvoice/create">Agregar Batch</LinkAddButton>;
}

// Pagination component
function Pagination({ pagination, onPageChange, isLoading = false }) {
  const { page, pages, total } = pagination;

  // Generate page numbers to display
  const getPageNumbers = () => {
    const pageNumbers = [];
    const maxPagesToShow = 5;

    if (pages <= maxPagesToShow) {
      // Show all pages if there are fewer than maxPagesToShow
      for (let i = 1; i <= pages; i += 1) {
        pageNumbers.push(i);
      }
    } else {
      // Always include first page
      pageNumbers.push(1);

      // Calculate start and end of page range
      let start = Math.max(2, page - 1);
      let end = Math.min(pages - 1, page + 1);

      // Adjust if we're near the beginning
      if (page <= 3) {
        end = Math.min(pages - 1, 4);
      }

      // Adjust if we're near the end
      if (page >= pages - 2) {
        start = Math.max(2, pages - 3);
      }

      // Add ellipsis if needed
      if (start > 2) {
        pageNumbers.push('...');
      }

      // Add page numbers
      for (let i = start; i <= end; i += 1) {
        pageNumbers.push(i);
      }

      // Add ellipsis if needed
      if (end < pages - 1) {
        pageNumbers.push('...');
      }

      // Always include last page
      if (pages > 1) {
        pageNumbers.push(pages);
      }
    }

    return pageNumbers;
  };

  const getButtonClassName = (currentPage, pageNumber, loading) => {
    if (currentPage === pageNumber) {
      if (loading) {
        return 'bg-indigo-400 text-white';
      }
      return 'bg-indigo-600 text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600';
    }
    return `text-gray-900 ring-1 ring-inset ring-gray-300 ${
      loading
        ? 'opacity-50 cursor-not-allowed'
        : 'hover:bg-gray-50 focus:outline-offset-0'
    }`;
  };

  return (
    <div className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6">
      <div className="flex flex-1 justify-between sm:hidden">
        <button
          type="button"
          onClick={() => !isLoading && onPageChange(Math.max(1, page - 1))}
          disabled={page === 1 || isLoading}
          className={`relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 ${
            page === 1 || isLoading
              ? 'opacity-50 cursor-not-allowed'
              : 'hover:bg-gray-50'
          }`}
        >
          Anterior
        </button>
        <button
          type="button"
          onClick={() => !isLoading && onPageChange(Math.min(pages, page + 1))}
          disabled={page === pages || isLoading}
          className={`relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 ${
            page === pages || isLoading
              ? 'opacity-50 cursor-not-allowed'
              : 'hover:bg-gray-50'
          }`}
        >
          Siguiente
        </button>
      </div>
      <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
        <div>
          <p className="text-sm text-gray-700">
            Mostrando{' '}
            <span className="font-medium">
              {(page - 1) * pagination.limit + 1}
            </span>{' '}
            a{' '}
            <span className="font-medium">
              {Math.min(page * pagination.limit, total)}
            </span>{' '}
            de <span className="font-medium">{total}</span> resultados
          </p>
        </div>
        <div>
          <nav
            className="isolate inline-flex -space-x-px rounded-md shadow-sm"
            aria-label="Paginación"
          >
            <button
              type="button"
              onClick={() => !isLoading && onPageChange(Math.max(1, page - 1))}
              disabled={page === 1 || isLoading}
              className={`relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 ${
                page === 1 || isLoading
                  ? 'opacity-50 cursor-not-allowed'
                  : 'hover:bg-gray-50'
              }`}
              aria-label="Página anterior"
              title="Página anterior"
            >
              <span className="sr-only">Anterior</span>
              <svg
                className="h-5 w-5"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fillRule="evenodd"
                  d="M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z"
                  clipRule="evenodd"
                />
              </svg>
            </button>

            {getPageNumbers().map((pageNumber) =>
              pageNumber === '...' ? (
                <span
                  key={`ellipsis-${pageNumber}-${Math.random()}`}
                  className="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-700 ring-1 ring-inset ring-gray-300"
                >
                  ...
                </span>
              ) : (
                <button
                  type="button"
                  key={`page-${pageNumber}`}
                  onClick={() => !isLoading && onPageChange(pageNumber)}
                  disabled={isLoading}
                  className={`relative inline-flex items-center px-4 py-2 text-sm font-semibold ${getButtonClassName(
                    page,
                    pageNumber,
                    isLoading
                  )}`}
                  aria-label={`Página ${pageNumber}`}
                  title={`Ir a página ${pageNumber}`}
                >
                  {pageNumber}
                </button>
              )
            )}

            <button
              type="button"
              onClick={() =>
                !isLoading && onPageChange(Math.min(pages, page + 1))
              }
              disabled={page === pages || isLoading}
              className={`relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 ${
                page === pages || isLoading
                  ? 'opacity-50 cursor-not-allowed'
                  : 'hover:bg-gray-50'
              }`}
              aria-label="Página siguiente"
              title="Página siguiente"
            >
              <span className="sr-only">Siguiente</span>
              <svg
                className="h-5 w-5"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fillRule="evenodd"
                  d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
                  clipRule="evenodd"
                />
              </svg>
            </button>
          </nav>
        </div>
      </div>
    </div>
  );
}

function BatchList() {
  const navigate = useNavigate();
  const { reInvoice } = useContext(StoresContext);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);

  const { data, isLoading, isEmpty, mutate } = useAPI(
    `/reinvoice?page=${currentPage}&limit=${itemsPerPage}`
  );

  // Invalidate cache on mount
  useEffect(() => {
    mutate();
  }, []); // Empty dependency array means this runs once on mount

  useEffect(() => {
    if (data && !isLoading) {
      runInAction(() => {
        reInvoice.items = data.items || [];
        reInvoice.pagination = data.pagination || {
          total: reInvoice.items.length,
          page: currentPage,
          limit: itemsPerPage,
          pages: Math.ceil(reInvoice.items.length / itemsPerPage)
        };
      });
    }
  }, [data, isLoading, reInvoice, currentPage, itemsPerPage]);

  useEffect(() => {
    rowMenuConfig.onClick = (onClick, row) =>
      onClick({ row, navigate, reInvoice, mutate, setCurrentPage });
  }, [navigate, reInvoice, mutate]);

  const showTable = !isLoading && !isEmpty;

  const handleRowClick = (evt, row) => {
    navigate(`/reinvoice/view/${row.id}`);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
    reInvoice.pagination.page = page;
  };

  return (
    <div className="flex flex-col min-h-screen">
      <div className="flex-1 max-w-full mx-auto px-4 sm:px-6 md:px-8">
        <TablePageHeading
          backUrl="/"
          title="Facturar en batch"
          description="Un listado de clientes, haga click en uno para ver y manejar sus portafolios, o crea un cliente nuevo haciendo click en el boton."
        >
          {!isLoading && data?.items?.length > 0 && <CreateBatchButton />}
        </TablePageHeading>

        <div className="bg-white rounded-lg shadow min-h-[400px] relative">
          {isLoading && (
            <div className="absolute inset-0 flex items-center justify-center bg-white bg-opacity-75">
              <div className="flex items-center space-x-3">
                <svg
                  className="animate-spin h-5 w-5 text-indigo-600"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  />
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  />
                </svg>
                <span className="text-gray-500">Cargando datos...</span>
              </div>
            </div>
          )}

          {isEmpty && !isLoading && (
            <div className="flex flex-col items-center justify-center h-[400px] p-8">
              <p className="text-gray-500 mb-4 text-center">
                No hay registros de batch disponibles
              </p>
              <CreateBatchButton />
            </div>
          )}

          {showTable && (
            <div className="overflow-hidden">
              <Table
                columns={columns}
                rows={data.items || []}
                rowMenuConfig={rowMenuConfig}
                onRowClick={handleRowClick}
                rowKey="id"
                striped
                hover
              />
            </div>
          )}
        </div>

        {(data?.pagination?.pages > 1 ||
          (isLoading && reInvoice.pagination.pages > 1)) && (
          <div className="mt-4">
            <Pagination
              pagination={data?.pagination || reInvoice.pagination}
              onPageChange={handlePageChange}
              isLoading={isLoading}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default BatchList;
