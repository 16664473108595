import useSWR from 'swr';

import http from '../Services/HTTP';

const fetcher = (url) => http.get(url).then((response) => response.json());

const useAPI = (path) => {
  // Use the full path as the key for SWR, which will automatically refetch when the path changes
  const { data, error, mutate } = useSWR(path, fetcher, {
    revalidateOnFocus: true,
    revalidateOnReconnect: true,
    refreshInterval: 5000, // Poll every 5 seconds
    dedupingInterval: 2000 // Dedupe requests within 2 seconds
  });

  // Check if data is in the new pagination format (has items and pagination properties)
  const isPaginatedResponse = data && data.items && data.pagination;

  // For paginated responses, check if items array is empty
  // For non-paginated responses, check if data array is empty
  const isEmpty = isPaginatedResponse
    ? data.items.length === 0
    : data && data.length === 0;

  const reload = () => mutate();

  return {
    data,
    isLoading: !error && !data,
    isEmpty,
    isError: !!error,
    error,
    mutate,
    reload
  };
};

export default useAPI;
